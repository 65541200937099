
/* link */
a {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

/* required */
span.required {
display: inline-block;
vertical-align: top;
color: #d32f2f;
margin-left: 5px;
padding-right: 8px;
font-size: 2rem;
}

span.field-label,
span.required {
background: #fff;
}

/* loader */
.dots-loader {
  display: flex;
  height: 250px;;
  justify-content: center;
  align-items: center;
}

.dots-loader .dot {
  height: 25px;
width: 25px;
background-color: #455a64;
margin: 10px;
border-radius: 100%;
}

.dots-loader .dot:nth-child(1) {
animation: bounce 1s linear infinite;
}

.dots-loader .dot:nth-child(2) {
animation: bounce 1s linear 0.3s infinite;
}

.dots-loader .dot:nth-child(3) {
animation: bounce 1s linear 0.6s infinite;
}

@keyframes bounce {
0% {
  transform: translateY(20px);
  opacity: 0.5;
}
50% {
  transform: translateY(-20px);
}
100% {
  transform: translateY(20px);
  opacity: 0.8;
}
}

/* s alert */
.app-alert {
z-index: 1000001;
font-weight: 600;
}

.s-alert-wrapper { 
z-index: 1000001; 
display: flex;
justify-content: center;
position: relative;
}

.s-alert-wrapper .s-alert-box {
padding: 12px 25px;
min-width: 250px;
border-radius: 9px;
text-align: center;
}

.s-alert-wrapper .s-alert-box-inner span {
font-size: 14px;
font-weight: 700;
}

.s-alert-wrapper .s-alert-box-inner span i {
margin-right: 8px;
}

.s-alert-wrapper .s-alert-success {
background: #2e7d32;	
}

.s-alert-wrapper .s-alert-info {
background: #37474f;
}

.s-alert-wrapper .s-alert-info span i {
color: #64dd17;
}

.s-alert-wrapper .s-alert-error {
background: #c62828;
}

/* material UI */
.MuiPickersBasePicker-pickerView { background: #ffffff; }

/* tiny mce */
.tox-tinymce-aux,
.tox .tox-dialog,
.tox .tox-dialog-wrap {
  z-index: 5000 !important;
}

/* RC Select */
.rc-select {
display: inline-block;
font-size: 12px;
width: 100px;
position: relative;
}
.rc-select-disabled,
.rc-select-disabled input {
cursor: not-allowed;
}
.rc-select-disabled .rc-select-selector {
opacity: 0.3;
}
.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
box-sizing: border-box;
width: 12px;
height: 12px;
border-radius: 100%;
border: 2px solid #999;
border-top-color: transparent;
border-bottom-color: transparent;
transform: none;
margin-top: 4px;
animation: rcSelectLoadingIcon 0.5s infinite;
}
.rc-select .rc-select-selection-placeholder {
opacity: 0.4;
pointer-events: none;
}
.rc-select .rc-select-selection-search-input {
appearance: none;
}
.rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
display: none;
appearance: none;
}
.rc-select-single .rc-select-selector {
display: flex;
position: relative;
}
.rc-select-single .rc-select-selector .rc-select-selection-search {
width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-search-input {
width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-item,
.rc-select-single .rc-select-selector .rc-select-selection-placeholder {
position: absolute;
top: 1px;
left: 3px;
pointer-events: none;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
padding: 1px;
border: 1px solid #000;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
border: none;
outline: none;
background: rgba(255, 0, 0, 0.2);
width: 100%;
}
.rc-select-multiple .rc-select-selector {
display: flex;
flex-wrap: wrap;
padding: 1px;
border: 1px solid #000;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item {
flex: none;
background: #bbb;
border-radius: 4px;
margin-right: 2px;
padding: 0 8px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
cursor: not-allowed;
opacity: 0.5;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-overflow {
display: flex;
flex-wrap: wrap;
width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-overflow-item {
flex: none;
max-width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search {
position: relative;
max-width: 100%;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
padding: 1px;
font-family: system-ui;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
position: absolute;
z-index: 999;
white-space: nowrap;
position: none;
left: 0;
top: 0;
visibility: hidden;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
border: none;
outline: none;
background: rgba(255, 0, 0, 0.2);
width: 100%;
}
.rc-select-allow-clear.rc-select-multiple .rc-select-selector {
padding-right: 20px;
}
.rc-select-allow-clear .rc-select-clear {
position: absolute;
right: 20px;
top: 0;
}
.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
padding-right: 20px;
}
.rc-select-show-arrow .rc-select-arrow {
pointer-events: none;
position: absolute;
right: 5px;
top: 0;
}
.rc-select-show-arrow .rc-select-arrow-icon::after {
content: '';
border: 5px solid transparent;
width: 0;
height: 0;
display: inline-block;
border-top-color: #999;
transform: translateY(5px);
}
.rc-select-focused .rc-select-selector {
border-color: blue !important;
}
.rc-select-dropdown {
border: 1px solid green;
min-height: 100px;
position: absolute;
background: #fff;
}
.rc-select-dropdown-hidden {
display: none;
}
.rc-select-item {
font-size: 16px;
line-height: 1.5;
padding: 4px 16px;
}
.rc-select-item-group {
color: #999;
font-weight: bold;
font-size: 80%;
}
.rc-select-item-option {
position: relative;
}
.rc-select-item-option-grouped {
padding-left: 24px;
}
.rc-select-item-option .rc-select-item-option-state {
position: absolute;
right: 0;
top: 4px;
pointer-events: none;
}
.rc-select-item-option-active {
background: green;
}
.rc-select-item-option-disabled {
color: #999;
}
.rc-select-item-empty {
text-align: center;
color: #999;
}
.rc-select-selection__choice-zoom {
transition: all 0.3s;
}
.rc-select-selection__choice-zoom-appear {
opacity: 0;
transform: scale(0.5);
}
.rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
opacity: 1;
transform: scale(1);
}
.rc-select-selection__choice-zoom-leave {
opacity: 1;
transform: scale(1);
}
.rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
opacity: 0;
transform: scale(0.5);
}
.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
animation-duration: 0.3s;
animation-fill-mode: both;
transform-origin: 0 0;
opacity: 0;
animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
animation-play-state: paused;
}
.rc-select-dropdown-slide-up-leave {
animation-duration: 0.3s;
animation-fill-mode: both;
transform-origin: 0 0;
opacity: 1;
animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
animation-play-state: paused;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
animation-name: rcSelectDropdownSlideUpIn;
animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
animation-name: rcSelectDropdownSlideUpOut;
animation-play-state: running;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
animation-name: rcSelectDropdownSlideDownIn;
animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
animation-name: rcSelectDropdownSlideDownOut;
animation-play-state: running;
}
@keyframes rcSelectDropdownSlideUpIn {
0% {
  opacity: 0;
  transform-origin: 0% 0%;
  transform: scaleY(0);
}
100% {
  opacity: 1;
  transform-origin: 0% 0%;
  transform: scaleY(1);
}
}
@keyframes rcSelectDropdownSlideUpOut {
0% {
  opacity: 1;
  transform-origin: 0% 0%;
  transform: scaleY(1);
}
100% {
  opacity: 0;
  transform-origin: 0% 0%;
  transform: scaleY(0);
}
}
@keyframes rcSelectLoadingIcon {
0% {
  transform: rotate(0);
}
100% {
  transform: rotate(360deg);
}
}
